import { Link as RouterLink } from 'react-router-dom';
import { Box, Hidden, Link, Tooltip, Typography, Container } from '@material-ui/core';
import { Helmet } from 'react-helmet-async';
import useAuth from 'src/hooks/useAuth';
import Auth0Login from '../LoginAuth0';
import FirebaseAuthLogin from '../LoginFirebaseAuth';
import JWTLogin from '../LoginJWT';
import AmplifyLogin from '../LoginAmplify';

import { useTranslation } from 'react-i18next';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { REX3_GREY, REX3_RED, REX3_WHITE } from 'src/theme/schemes/Rex3Theme';
import { toAbsoluteUrl } from 'src/utils/utils';

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    display: 'flex',
    height: '100%',
    flex: 1
  },
  mainContent: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'auto',
    backgroundColor: REX3_GREY,
    minHeight: '100vh'
  },
  sidebarWrapper: {
    background: REX3_RED,
    minHeight: '100vh',
    width: 440,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  sidebarContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    padding: theme.spacing(6),
    minHeight: '100vh'
  },
  cardImg: {
    borderRadius: '100%',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${REX3_WHITE}`,
    transition: theme.transitions.create(['border']),
    position: 'absolute',
    '&:hover': {
      borderColor: REX3_WHITE
    }
  },
  typographyH1: {
    fontSize: '2.063rem'
  },
  cardText: {
    padding: theme.spacing(5, 4, 5, 4)
  },
  signIn: { fontSize: '1.875rem', marginBottom: theme.spacing(1), fontWeight: 500 },
  signInto: { fontSize: '1.063rem', marginBottom: theme.spacing(3), fontWeight: 'normal' },
  link: { color: REX3_RED, marginLeft: theme.spacing(1) },
  cardfactory: { width: '100%' }
}));

function LoginCover() {
  const classes = useStyles();
  const { method } = useAuth() as any;
  const { t }: { t: any } = useTranslation();

  return (
    <>
      <Helmet>
        <title>Login - Cover</title>
      </Helmet>
      <Box className={classes.content}>
        <Hidden mdDown>
          <Box className={classes.sidebarWrapper}>
            <img className={classes.cardfactory} alt="BTC" src={toAbsoluteUrl('/static/images/logo/cardfactory.png')} />
          </Box>
        </Hidden>
        <Box className={classes.mainContent}>
          <Container maxWidth="sm">
            <Box className={classes.cardText}>
              <Box textAlign="center">
                <Typography variant="h2" className={classes.signIn}>
                  {t('Sign in')}
                </Typography>
                <Typography variant="h4" color="textSecondary" className={classes.signInto}>
                  {t('Fill in the fields below to sign into your account.')}
                </Typography>
              </Box>
              {method === 'Auth0' && <Auth0Login />}
              {method === 'FirebaseAuth' && <FirebaseAuthLogin />}
              {method === 'JWT' && <JWTLogin />}
              {method === 'Amplify' && <AmplifyLogin />}
              <Box my={4}>
                <Typography component="span" variant="subtitle2" color="textPrimary">
                  {t('Don’t have an account, yet?')}
                </Typography>
                <Link component={RouterLink} className={classes.link} underline="hover" to="/account/register">
                  <b>Sign up here</b>
                </Link>
              </Box>
              {method !== 'Auth0' && (
                <Tooltip title={t('Used only for the live preview demonstration !')}>
                  <Alert severity="warning">
                    Use <b>rex3@rex3.com</b> and password <b>rex3</b>
                  </Alert>
                </Tooltip>
              )}
            </Box>
          </Container>
        </Box>
      </Box>
    </>
  );
}

export default LoginCover;
