import React from 'react';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import { Control } from 'react-hook-form';
import { TextField, TextFieldProps } from '@material-ui/core';

import ReactHookFormAutocomplete from '../formControl/ReactHookFormAutocomplete';
import { REX3_GREY_2 } from 'src/theme/schemes/Rex3Theme';
import { uuidv4 } from 'src/utils/datatable';
import { AutocompleteChangeDetails, AutocompleteChangeReason, FilterOptionsState } from '@material-ui/lab/useAutocomplete';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useInputStyle } from 'src/theme/useInputStyle';

type PropsContent<T, K> = {
  label: string;
  labelAccessor?: string;
  required: boolean;
  error?: boolean;
  helperText?: string;
  isForm: boolean;
  control?: Control<T> | null;
  value: K;
  name: keyof T;
  useTranslate?: boolean;
  textIfEmptyData?: string;
  fullWidth: boolean;
  minWidth: number | null;
  maxWidth: number | null;
  onChangeAutocomplete:
    | ((
        // eslint-disable-next-line @typescript-eslint/ban-types
        event: React.ChangeEvent<{}>,
        value: K | K[] | null,
        reason: AutocompleteChangeReason,
        details?: AutocompleteChangeDetails<K> | undefined
      ) => void)
    | undefined;
  multiple: boolean;
  initialValue: K | null;
  options: Array<K>;
  filterOptions?: (options: any, state: FilterOptionsState<any>) => any[];
  textFieldSize?: TextFieldProps['size'];
};

export default function MyAutocomplete<T, K>({
  isForm,
  control,
  options,
  name,
  initialValue,
  label,
  labelAccessor = 'title',
  error,
  helperText,
  required,
  fullWidth,
  multiple,
  onChangeAutocomplete,
  value,
  minWidth,
  maxWidth,
  filterOptions,
  textFieldSize = 'medium'
}: PropsContent<T, K>) {
  const inputClasses = useInputStyle(fullWidth ? { minWidth: null, maxWidth: null } : { minWidth, maxWidth });

  const { t } = useTranslation();

  return (
    <>
      {isForm && control ? (
        <ReactHookFormAutocomplete<T, K>
          name={name}
          label={label}
          labelAccessor={labelAccessor}
          defaultValue={initialValue}
          options={options}
          required={required}
          control={control}
          error={error}
          helperText={helperText}
          fullWidth={fullWidth}
          minWidth={minWidth}
          maxWidth={maxWidth}
          multiple={multiple}
          filterOptions={filterOptions}
          textFieldSize={textFieldSize}
        />
      ) : (
        <Autocomplete
          id={`autocomplete-${label}-${uuidv4()}`}
          classes={inputClasses}
          fullWidth={fullWidth}
          multiple={multiple}
          // @ts-ignore
          onChange={onChangeAutocomplete}
          options={options}
          getOptionLabel={(option) => get(option, [labelAccessor], '')}
          getOptionSelected={(option: K, value: K) => get(option, ['id']) === get(value, ['id'])}
          filterSelectedOptions
          filterOptions={filterOptions}
          // @ts-ignore
          value={value}
          noOptionsText={t('No options text')}
          loadingText={t('Loading')}
          renderInput={(params) => (
            <TextField
              {...params}
              name={name as string}
              variant="outlined"
              label={label}
              error={error}
              required={required}
              helperText={helperText}
              size={textFieldSize}
              InputLabelProps={{
                style: {
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  width: '100%',
                  color: REX3_GREY_2
                }
              }}
            />
          )}
        />
      )}
    </>
  );
}
