import gql from 'graphql-tag';

export type Team = { id: string; name: string };

export type Subject = {
  id: string;
  name: string;
  position: string;
  rc: string;
  legalStatus: string;
  teams: Array<{ metadata: Array<{ name: string; value: string }>; element: Team }>;
};

export interface GetSubjectListingData {
  getSubjectListing: {
    totalCount: number;
    edges: Array<{ cursor: string; node: Array<Subject> }>;
  };
}
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface GetSubjectListingVars {}

export default gql`
  query getSubjectListing {
    getSubjectListing {
      totalCount
      edges {
        cursor
        node {
          id
          name
          position
          rc
          legalStatus
          teams {
            ... on object_Subject_teams {
              metadata {
                name
                value
              }
              element {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;
