import React from 'react';
import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';

type Props = {
  fontSize: number;
  borderWidth: number;
  color: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: ({ fontSize }: Props) => fontSize,
    height: ({ fontSize }: Props) => fontSize,
    position: 'relative'
  },
  spinner: {
    '&::before': {
      content: '""',
      boxSizing: 'border-box',
      position: 'absolute',
      top: '50%',
      left: '50%',
      width: ({ fontSize }: Props) => fontSize,
      height: ({ fontSize }: Props) => fontSize,
      marginTop: ({ fontSize }: Props) => -(fontSize / 2),
      marginLeft: ({ fontSize }: Props) => -(fontSize / 2),
      borderRadius: ({ fontSize }: Props) => 2.5 * fontSize,
      borderTop: ({ borderWidth, color }: Props) =>
        `${borderWidth}px solid ${color}`,
      borderRight: ({ borderWidth }: Props) =>
        `${borderWidth}px solid transparent`,
      animation: `$spinner 1.2s linear infinite`
    }
  },
  '@keyframes spinner': {
    to: { transform: 'rotate(360deg)' }
  }
}));

function Spinner({ fontSize, borderWidth, color }: Props) {
  const classes = useStyles({ fontSize, borderWidth, color });
  return (
    <div className={classes.container}>
      <div className={classes.spinner} />
    </div>
  );
}

export default Spinner;
