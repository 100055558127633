import { FC, ReactNode, useState, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { SidebarContext } from 'src/contexts/SidebarContext';

import PropTypes from 'prop-types';
import { Button, Collapse, ListItem, makeStyles, Theme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ExpandLessTwoToneIcon from '@material-ui/icons/ExpandLessTwoTone';
import ExpandMoreTwoToneIcon from '@material-ui/icons/ExpandMoreTwoTone';
import { REX3_GREY_2, REX3_RED, REX3_WHITE } from 'src/theme/schemes/Rex3Theme';

const useStyles = makeStyles((theme: Theme) => ({
  buttonStyle: ({ menuToggle, active }: { menuToggle: boolean; active: boolean }) => ({
    padding: theme.spacing(1.5, 1.5, 1.5, 1.5),
    borderRadius: 0,
    color: menuToggle || active ? REX3_WHITE : REX3_GREY_2,
    backgroundColor: menuToggle || active ? REX3_RED : 'transparent',
    '&:hover': {
      color: REX3_WHITE,
      backgroundColor: REX3_RED
    }
  }),
  labelButtonStyle: { justifyContent: 'space-between' },
  containerIcon: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
  textStyle: {
    marginLeft: theme.spacing(2)
  },
  children: {
    padding: 0,
    flexDirection: 'column'
  },
  collapse: { width: '100%' }
}));

interface SidebarMenuItemProps {
  children?: ReactNode;
  link?: string;
  icon?: any;
  iconProps?: any;
  badge?: string;
  open?: boolean;
  active?: boolean;
  name: string;
  state: any;
}

const SidebarMenuItem: FC<SidebarMenuItemProps> = ({
  children,
  link,
  icon: Icon,
  iconProps,
  badge,
  open: openParent,
  active,
  name,
  state,
  ...rest
}) => {
  const [menuToggle, setMenuToggle] = useState<boolean>(openParent);
  const classes = useStyles({ menuToggle, active });
  const { t }: { t: any } = useTranslation();
  const { toggleSidebar } = useContext(SidebarContext);

  const toggleMenu = (): void => {
    setMenuToggle((Open) => !Open);
  };

  if (children) {
    return (
      <ListItem className={classes.children} key={name} {...rest}>
        <Button
          classes={{
            root: classes.buttonStyle,
            label: classes.labelButtonStyle
          }}
          component={NavLink}
          endIcon={menuToggle ? <ExpandLessTwoToneIcon fontSize="small" /> : <ExpandMoreTwoToneIcon fontSize="small" />}
          onClick={toggleMenu}
          to={link}
          state={state}
          fullWidth
        >
          <>
            <div className={classes.containerIcon}>
              {Icon && <Icon fontSize="small" {...iconProps} />}
              <span className={classes.textStyle}>{t(name)}</span>
            </div>
          </>
        </Button>
        <Collapse className={classes.collapse} in={menuToggle}>
          {children}
        </Collapse>
      </ListItem>
    );
  }

  return (
    <ListItem key={name} className={classes.children} {...rest}>
      <Button
        classes={{
          root: classes.buttonStyle,
          label: classes.labelButtonStyle
        }}
        component={NavLink}
        onClick={toggleSidebar}
        to={link}
        state={state}
        fullWidth
      >
        <div className={classes.containerIcon}>
          {Icon && <Icon fontSize="small" {...iconProps} />}
          <span className={classes.textStyle}>{t(name)}</span>
        </div>
      </Button>
    </ListItem>
  );
};

SidebarMenuItem.propTypes = {
  children: PropTypes.node,
  active: PropTypes.bool,
  link: PropTypes.string,
  icon: PropTypes.elementType,
  badge: PropTypes.string,
  open: PropTypes.bool,
  name: PropTypes.string.isRequired
};

SidebarMenuItem.defaultProps = {
  open: false,
  active: false
};

export default SidebarMenuItem;
