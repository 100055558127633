import gql from 'graphql-tag';
import { Property } from 'src/types/property';

export interface GetPropertyData {
  getProperty: Property;
}

export interface GetPropertyVars {
  id: string | number;
}

export default gql`
  query getProperty($id: Int) {
    getProperty(id: $id) {
      id
      name
      initiales
      brands {
        id
        name
        brandTypes {
          id
          name
          program {
            ... on object_Program {
              id
              printRun
              price
              availableFrom
              availableUntil
              cardTypes {
                ... on object_CardType {
                  id
                  identifier
                  name
                  foilStamp
                  color
                  colorName
                  cardType
                  serialization
                  pourcentage
                  printRunDescription
                  technology
                }
              }
              programProperty {
                ... on object_Property {
                  id
                  name
                }
              }
              product {
                ... on object_Product {
                  id
                  sku
                  name
                  coanumber
                  setNumberStart
                  setNumberEnd
                  productType
                  description
                  extraDescription
                  information {
                    ... on object_Text {
                      id
                      order
                      text
                    }
                  }
                  whatsnew {
                    ... on object_Text {
                      id
                      order
                      text
                    }
                  }
                  design {
                    ... on object_Design {
                      id
                      name
                    }
                  }
                }
              }
              waves {
                id
              }
            }
          }
        }
      }
    }
  }
`;
