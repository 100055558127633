import { forwardRef, Ref, useState, ReactElement, ChangeEvent } from 'react';
import {
  Avatar,
  Link,
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  TextField,
  Theme,
  Tooltip,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  Hidden
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import SearchTwoToneIcon from '@material-ui/icons/SearchTwoTone';
import FindInPageTwoToneIcon from '@material-ui/icons/FindInPageTwoTone';
import { useTranslation } from 'react-i18next';
import ChevronRightTwoToneIcon from '@material-ui/icons/ChevronRightTwoTone';
import SearchTwoTone from '@material-ui/icons/SearchTwoTone';
import { makeStyles } from '@material-ui/core';
import { REX3_WHITE } from 'src/theme/schemes/Rex3Theme';

const Transition = forwardRef(function Transition(props: TransitionProps & { children?: ReactElement<any, any> }, ref: Ref<unknown>) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme: Theme) => ({
  dialogWrapper: {
    '.MuiDialog-container': {
      height: 'auto'
    },
    '.MuiDialog-paperScrollPaper': {
      maxHeight: 'calc(100vh - 64px)'
    }
  },
  searchInputWrapper: {
    background: REX3_WHITE,

    '.MuiInputBase-input': {
      fontSize: '1.063rem'
    }
  },
  dialogTitleWrapper: {
    background: REX3_WHITE,
    padding: theme.spacing(3)
  }
}));

function HeaderSearch() {
  const classes = useStyles();
  const { t }: { t: any } = useTranslation();

  const [openSearchResults, setOpenSearchResults] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setSearchValue(event.target.value);

    if (event.target.value) {
      if (!openSearchResults) {
        setOpenSearchResults(true);
      }
    } else {
      setOpenSearchResults(false);
    }
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip arrow title={t('Search')}>
        <IconButton color="primary" onClick={handleClickOpen} size="medium">
          <SearchTwoToneIcon fontSize="small" />
        </IconButton>
      </Tooltip>

      <Dialog
        className={classes.dialogWrapper}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        maxWidth="md"
        fullWidth
        scroll="paper"
        onClose={handleClose}
      >
        <DialogTitle className={classes.dialogTitleWrapper}>
          <TextField
            className={classes.searchInputWrapper}
            value={searchValue}
            autoFocus={true}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchTwoTone fontSize="small" />
                </InputAdornment>
              )
            }}
            placeholder={t('Search terms here...')}
            fullWidth
            label={t('Search')}
          />
        </DialogTitle>
        <Divider />

        {openSearchResults && (
          <DialogContent>
            <Box sx={{ pt: 0, pb: 1 }} display="flex" justifyContent="space-between">
              <Typography variant="body2" component="span">
                {t('Search results for')}{' '}
                <Typography
                  /*  sx={{ fontWeight: 'bold' }} */
                  variant="body1"
                  component="span"
                >
                  {searchValue}
                </Typography>
              </Typography>
              <Link href="#" variant="body2" underline="hover">
                {t('Advanced search')}
              </Link>
            </Box>
            <Divider /* sx={{ my: 1 }} */ />
            <List disablePadding>
              <ListItem button>
                <Hidden smDown>
                  <ListItemAvatar>
                    <Avatar
                    /*  sx={{
                        background: (theme: Theme) =>
                          theme.palette.secondary.main
                      }} */
                    >
                      <FindInPageTwoToneIcon fontSize="small" />
                    </Avatar>
                  </ListItemAvatar>
                </Hidden>
                <Box flex="1">
                  <Box display="flex" justifyContent="space-between">
                    <Link href="#" underline="hover" /* sx={{ fontWeight: 'bold' }} */ variant="body2">
                      {t('Dashboard for Healthcare Platform')}
                    </Link>
                  </Box>
                  <Typography
                    component="span"
                    variant="body2"
                    /* sx={{
                      color: (theme: Theme) =>
                        lighten(theme.palette.secondary.main, 0.5)
                    }} */
                  >
                    {t('This page contains all the necessary information for managing all hospital staff.')}
                  </Typography>
                </Box>
                <ChevronRightTwoToneIcon fontSize="small" />
              </ListItem>
              <Divider /* sx={{ my: 1 }} */ component="li" />
              <ListItem button>
                <Hidden smDown>
                  <ListItemAvatar>
                    <Avatar
                    /*  sx={{
                        background: (theme: Theme) =>
                          theme.palette.secondary.main
                      }} */
                    >
                      <FindInPageTwoToneIcon fontSize="small" />
                    </Avatar>
                  </ListItemAvatar>
                </Hidden>
                <Box flex="1">
                  <Box display="flex" justifyContent="space-between">
                    <Link href="#" underline="hover" /* sx={{ fontWeight: 'bold' }} */ variant="body2">
                      {t('Example Projects Application')}
                    </Link>
                  </Box>
                  <Typography
                    component="span"
                    variant="body2"
                    /* sx={{
                      color: (theme: Theme) =>
                        lighten(theme.palette.secondary.main, 0.5)
                    }} */
                  >
                    {t('This is yet another search result pointing to a app page.')}
                  </Typography>
                </Box>
                <ChevronRightTwoToneIcon fontSize="small" />
              </ListItem>
              <Divider /* sx={{ my: 1 }} */ component="li" />
              <ListItem button>
                <Hidden smDown>
                  <ListItemAvatar>
                    <Avatar
                    /*  sx={{
                        background: (theme: Theme) =>
                          theme.palette.secondary.main
                      }} */
                    >
                      <FindInPageTwoToneIcon fontSize="small" />
                    </Avatar>
                  </ListItemAvatar>
                </Hidden>
                <Box flex="1">
                  <Box display="flex" justifyContent="space-between">
                    <Link href="#" underline="hover" /* sx={{ fontWeight: 'bold' }} */ variant="body2">
                      {t('Search Results Page')}
                    </Link>
                  </Box>
                  <Typography
                    component="span"
                    variant="body2"
                    /* sx={{
                      color: (theme: Theme) =>
                        lighten(theme.palette.secondary.main, 0.5)
                    }} */
                  >
                    {t('Choose if you would like to show or not this typography section here...')}
                  </Typography>
                </Box>
                <ChevronRightTwoToneIcon fontSize="small" />
              </ListItem>
            </List>
            <Divider /* sx={{ mt: 1, mb: 2 }}  */ />
            <Box sx={{ textAlign: 'center' }}>
              <Button color="primary">{t('View all search results')}</Button>
            </Box>
          </DialogContent>
        )}
      </Dialog>
    </>
  );
}

export default HeaderSearch;
