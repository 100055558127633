import React from 'react';
import { Theme } from '@material-ui/core';
import SpinnerContainer from '../spinner/SpinnerContainer';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) => ({ container: { minHeight: '100vh' } }));
function SuspenseLoader() {
  const classes = useStyles();
  return <SpinnerContainer className={classes.container} />;
}

export default SuspenseLoader;
