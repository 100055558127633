import { Box, Card, Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import { REX3_BLACK } from 'src/theme/schemes/Rex3Theme';

const useStyles = makeStyles((theme: Theme) => ({
  text: {
    color: `${REX3_BLACK} !important`
  },
  footerWrapper: {
    borderRadius: 0,
    marginTop: theme.spacing(3),
    boxShadow: 'none !important'
  }
}));

function Footer() {
  const classes = useStyles();
  return (
    <Card className={classes.footerWrapper}>
      <Container maxWidth="lg">
        <Box py={3} display={{ xs: 'block', md: 'flex' }} alignItems="center" textAlign={{ xs: 'center', md: 'left' }} justifyContent="space-between">
          <Box>
            <Typography className={classes.text} variant="body1">
              &copy; 2021 - Powered by Rex3
            </Typography>
          </Box>
          <Typography
            className={classes.text}
            /*         sx={{ pt: { xs: 2, md: 0 } }} */
            variant="body1"
          >
            Crafted by Galilee
          </Typography>
        </Box>
      </Container>
    </Card>
  );
}

export default Footer;
