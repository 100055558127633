import get from 'lodash/get';

export const dateFormat = 'DD/MM/YYYY';

export const FILTER_TYPE_CONTAIN = 'contain';
export const FILTER_TYPE_SELECT = 'select';
export const FILTER_TYPE_BETWEEN = 'between';
export const FILTER_TYPE_MULTISELECT = 'multiselect';

export const matchFiltersWithDataHub = (
  filters: Record<
    string,
    {
      filterValue:
        | string
        | {
            start: number | undefined;
            end: number | undefined;
          }
        | Array<string>;
      filterType: string;
    }
  >
) =>
  Object.entries(filters).reduce((acc, [key, value]) => {
    // eslint-disable-next-line @typescript-eslint/ban-types
    const previousAnd: Array<object> = get(acc, ['$and'], []);
    if (
      value.filterType === FILTER_TYPE_CONTAIN ||
      value.filterType === FILTER_TYPE_SELECT
    ) {
      const and = [
        ...previousAnd,
        {
          [key]: {
            $like:
              value.filterType === FILTER_TYPE_SELECT
                ? `${value.filterValue}`
                : `%${value.filterValue}%`
          }
        }
      ];
      return {
        ...acc,
        ...(and.length > 0 && { $and: and })
      };
    }
    if (value.filterType === FILTER_TYPE_BETWEEN) {
      const { start, end } = value.filterValue as {
        start: number | null;
        end: number | null;
      };
      const and = [
        ...previousAnd,
        { [key]: { $gte: start } },
        { [key]: { $lte: end } }
      ];
      return {
        ...acc,
        ...(and.length > 0 && { $and: and })
      };
    }
    if (value.filterType === FILTER_TYPE_MULTISELECT) {
      const previousOr = Object.entries(previousAnd).find(
        ([key]) => key === '$or'
      );
      // eslint-disable-next-line @typescript-eslint/ban-types
      const or: Array<object> = (value.filterValue as Array<string>).map(
        (e: string) => ({
          [key]: { $like: `${e}` }
        })
      );

      // eslint-disable-next-line @typescript-eslint/ban-types
      const finalOr: Array<object> = [...get(previousOr, ['$or'], []), ...or];
      // eslint-disable-next-line @typescript-eslint/ban-types
      const finalAnd: Array<object> = [
        ...Object.entries(previousAnd)?.filter(([key]) => key !== '$or')
      ];

      if (finalOr.length > 0) {
        // eslint-disable-next-line @typescript-eslint/ban-types
        finalAnd.push({ $or: finalOr } as object);
      }

      return {
        ...acc,
        ...(finalAnd.length > 0 && { $and: finalAnd })
      };
    }

    return acc;
  }, {});

export const uuidv4 = () =>
  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    // eslint-disable-next-line no-bitwise
    const r = (Math.random() * 16) | 0;
    // eslint-disable-next-line no-bitwise
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
