import React, { createContext, useState, useEffect } from 'react';
/* import { ORDER_TYPE, ORDER_CREATION_DATE_FIELD, ORDER_STATUS_FIELD, SELECT_ALL_STATUSES_SECTION } from '@/constants/order'; */
import arrayMove from 'array-move';
import { Column, SortingRule } from 'react-table';

import { EntityType, WAVE_TYPE } from 'src/types/entityType';
import { FILTER_TYPE_BETWEEN, matchFiltersWithDataHub } from 'src/utils/datatable';

const LOCAL_STORAGE_KEY = 'table';

type initProps<T> = {
  pageSize: number;
  page: number;
  sortBy: SortingRule<T>[];

  filters: Record<
    string,
    {
      filterValue:
        | string
        | {
            start: number | undefined;
            end: number | undefined;
          }
        | Array<string>;
      filterType: string;
    }
  >;
  columns: Array<{
    id: number;
    Header: string;
    accessor: string;
    Filter: JSX.Element;
  }>;
  columnsSort: Array<string>;
};

const init: initProps<unknown> = {
  pageSize: 25,
  page: 1,
  sortBy: [],
  filters: {},
  columns: [],
  columnsSort: []
};

const initialState: Record<EntityType, initProps<unknown>> = {
  [WAVE_TYPE]: {
    ...init,
    filters: {
      /*       [ORDER_STATUS_FIELD]: {
        filterType: FILTER_TYPE_MULTISELECT,
        filterValue: SELECT_ALL_STATUSES_SECTION,
      }, */
    },
    sortBy: [
      /* { id: ORDER_CREATION_DATE_FIELD, desc: true } */
    ]
  }
};

type FinalProps = {
  getEntityTableState: <T>(entityType: EntityType) => initProps<T>;
  persistSortBy: <T>(entityType: EntityType, sortBy: SortingRule<T>[]) => void;
  persistPageSize: (entityType: EntityType, pageSize: number) => void;
  persistPage: (entityType: EntityType, page: number) => void;
  persistFilters: (
    entityType: EntityType,
    newFilter: {
      [x: string]: {
        filterValue:
          | string
          | {
              start: number | undefined;
              end: number | undefined;
            }
          | Array<string>;
        filterType: string;
      };
    }
  ) => void;
  persistColumns: (entityType: EntityType, columns: Array<Column>) => void;
  getQueryVariables: (entityType: EntityType) => Record<string, unknown>;
  getColumns: (entityType: EntityType, columns: Array<Column>) => Column[];
  sortColumns: (entityType: EntityType, columns: Array<Column>, oldIndex: number, newIndex: number) => void;
  localLoaded: boolean;
};

export const TableContext = createContext<FinalProps>({} as any);

type Props = { children: JSX.Element };

/**
 * Table provider.
 * Persist filter, sort, columns table.
 *
 */
export default function TableProvider({ children }: Props) {
  const [tableState, setTableState] = useState(initialState);
  const [localLoaded, setLocalLoaded] = useState(false);

  useEffect(() => {
    const json = localStorage.getItem(LOCAL_STORAGE_KEY);
    const entitiesState = json ? JSON.parse(json) : initialState;
    setTableState(entitiesState);
    setLocalLoaded(true);
  }, []);

  const getEntityTableState = (entityType: EntityType) => tableState[entityType];

  const update = <T,>(entityType: EntityType, attribute: string, value: number | Array<string> | SortingRule<T>[]) => {
    const newTableState: Record<EntityType, initProps<T>> = {
      ...tableState,
      [entityType]: {
        ...tableState[entityType],
        [attribute]: value
      }
    };
    setTableState(newTableState);

    const withColumnsSort = Object.keys(newTableState).reduce((acc: Record<string, initProps<T>>, entityType) => {
      const { columns, ...rest } = newTableState[entityType as EntityType];
      if (columns) {
        acc[entityType] = { ...rest, columns: [] };
      } else {
        acc[entityType] = newTableState[entityType as EntityType];
      }
      return acc;
    }, {});

    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(withColumnsSort));
  };

  const persistPageSize = <T,>(entityType: EntityType, pageSize: number) => {
    if (entityType && tableState[entityType] && pageSize !== tableState[entityType].pageSize) {
      // update(entityType, 'pageSize', pageSize);
      // update(entityType, 'page', 1);

      const newTableState: Record<EntityType, initProps<T>> = {
        ...tableState,
        [entityType]: {
          ...tableState[entityType],
          pageSize,
          page: 1
        }
      };
      setTableState(newTableState);
    }
  };

  const persistPage = (entityType: EntityType, page: number) => {
    if (entityType && tableState[entityType] && page !== tableState[entityType].page) {
      update(entityType, 'page', page);
    }
  };

  const persistSortBy = <T,>(entityType: EntityType, sortBy: SortingRule<T>[]) => {
    const currentSortBy = tableState[entityType].sortBy;
    if (JSON.stringify(currentSortBy[0]) !== JSON.stringify(sortBy[0])) {
      update(entityType, 'sortBy', sortBy);
    }
  };

  const persistFilters = (
    entityType: EntityType,
    newFilter: {
      [x: string]: {
        filterValue:
          | string
          | {
              start: number | undefined;
              end: number | undefined;
            }
          | Array<string>;
        filterType: string;
      };
    }
  ) => {
    if (entityType && tableState[entityType].filters) {
      const keys = Object.keys(newFilter);

      // Remove filter if value is empty
      if (
        (keys[0] && newFilter[keys[0]].filterValue === '') ||
        (keys[0] &&
          newFilter[keys[0]].filterType === FILTER_TYPE_BETWEEN &&
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          /* @ts-ignore */
          newFilter[keys[0]].filterValue?.start === undefined &&
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          /* @ts-ignore */
          newFilter[keys[0]].filterValue?.end === undefined)
      ) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { [keys[0]]: removedFilter, ...newFilters } = tableState[entityType].filters;
        const newTableState = {
          ...tableState,
          [entityType]: {
            ...tableState[entityType],
            filters: newFilters
          }
        };
        setTableState(newTableState);
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(newTableState));
        return;
      }

      const newTableState = {
        ...tableState,
        [entityType]: {
          ...tableState[entityType],
          filters: {
            ...tableState[entityType].filters,
            ...newFilter
          }
        }
      };
      setTableState(newTableState);
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(newTableState));
    }
  };

  const persistColumns = (entityType: EntityType, columns: Array<Column>) => {
    update(entityType, 'columnsSort', columns.map((e) => e.accessor) as Array<string>);
  };

  const getColumns = (entityType: EntityType, columns: Array<Column>) => {
    if (tableState[entityType].columnsSort.length === 0) {
      return columns;
    }
    return tableState[entityType].columnsSort.map((accessor: string) => {
      const element = columns.find((element) => element.accessor === accessor);
      return element as Column;
    });
  };

  const sortColumns = (entityType: EntityType, columns: Array<Column>, oldIndex: number, newIndex: number) => {
    const currentCols = getColumns(entityType, columns);
    const cols = arrayMove(currentCols, oldIndex, newIndex);
    return cols;
  };

  const getQueryVariables = (entityType: EntityType) => {
    const { pageSize, filters, page, sortBy: stateSortBy } = getEntityTableState(entityType);

    const sortBy = stateSortBy[0] && stateSortBy[0].id;
    const sortOrder = stateSortBy[0] && stateSortBy[0].desc ? 'DESC' : 'ASC';
    const dataHubFilters = matchFiltersWithDataHub(filters);
    return {
      first: pageSize,
      after: (page - 1) * pageSize,
      ...(Object.keys(dataHubFilters).length > 0 && {
        filter: JSON.stringify(dataHubFilters)
      }),
      ...(sortBy && { sortBy, sortOrder })
    };
  };

  const contextValue: FinalProps = {
    getEntityTableState,
    persistSortBy,
    persistPageSize,
    persistPage,
    persistFilters,
    persistColumns,
    getQueryVariables,
    getColumns,
    sortColumns,
    localLoaded
  };

  return <TableContext.Provider value={contextValue}>{children}</TableContext.Provider>;
}
