import gql from 'graphql-tag';
import { BrandType } from 'src/types/brandType';

export interface GetBrandTypeData {
  getBrandType: BrandType;
}

export interface GetBrandTypeVars {
  id: string | number;
}

export default gql`
  query getBrandType($id: Int) {
    getBrandType(id: $id) {
      id
      name
      program {
        ... on object_Program {
          id
          printRun
          price
          availableFrom
          availableUntil
          cardTypes {
            ... on object_CardType {
              id
              identifier
              name
              foilStamp
              color
              colorName
              cardType
              serialization
              pourcentage
              printRunDescription
              technology
            }
          }
          programProperty {
            ... on object_Property {
              id
              name
            }
          }
          product {
            ... on object_Product {
              id
              sku
              name
              coanumber
              setNumberStart
              setNumberEnd
              productType
              description
              extraDescription
              information {
                ... on object_Text {
                  id
                  order
                  text
                }
              }
              whatsnew {
                ... on object_Text {
                  id
                  order
                  text
                }
              }
              design {
                ... on object_Design {
                  id
                  name
                }
              }
            }
          }
          waves {
            id
          }
        }
      }
    }
  }
`;
