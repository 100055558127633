import React from 'react';
import Spinner from './Spinner';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  spinnerCell: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6)
  }
}));

type Props = {
  className?: string;
};

export default function SpinnerContainer({ className }: Props) {
  const classes = useStyles();

  return (
    <div className={clsx(classes.spinnerCell, className)}>
      <Spinner fontSize={40} borderWidth={4} color="black" />
    </div>
  );
}
