import { ReactNode } from 'react';

/* import AnalyticsTwoToneIcon from '@material-ui/icons/AnalyticsTwoTone';
import AccountBalanceTwoToneIcon from '@material-ui/icons/AccountBalanceTwoTone';
import StoreTwoToneIcon from '@material-ui/icons/StoreTwoTone';
import AccountBalanceWalletTwoToneIcon from '@material-ui/icons/AccountBalanceWalletTwoTone';
import MonetizationOnTwoToneIcon from '@material-ui/icons/MonetizationOnTwoTone';
import KitchenTwoToneIcon from '@material-ui/icons/KitchenTwoTone';
import HealthAndSafetyTwoToneIcon from '@material-ui/icons/HealthAndSafetyTwoTone';
import ContactSupportTwoToneIcon from '@material-ui/icons/ContactSupportTwoTone';
import LocalLibraryTwoToneIcon from '@material-ui/icons/LocalLibraryTwoTone';
import DnsTwoToneIcon from '@material-ui/icons/DnsTwoTone';
import TaskAltTwoToneIcon from '@material-ui/icons/TaskAltTwoTone';
import EventNoteTwoToneIcon from '@material-ui/icons/EventNoteTwoTone';
import DocumentScannerTwoToneIcon from '@material-ui/icons/DocumentScannerTwoTone';
import WorkTwoToneIcon from '@material-ui/icons/WorkTwoTone';
import MarkunreadMailboxTwoToneIcon from '@material-ui/icons/MarkunreadMailboxTwoTone';
import QuestionAnswerTwoToneIcon from '@material-ui/icons/QuestionAnswerTwoTone';
import DashboardCustomizeTwoToneIcon from '@material-ui/icons/DashboardCustomizeTwoTone';
import AssignmentIndTwoToneIcon from '@material-ui/icons/AssignmentIndTwoTone';
import AccountTreeTwoToneIcon from '@material-ui/icons/AccountTreeTwoTone';
import StorefrontTwoToneIcon from '@material-ui/icons/StorefrontTwoTone';
import VpnKeyTwoToneIcon from '@material-ui/icons/VpnKeyTwoTone';
import ErrorTwoToneIcon from '@material-ui/icons/ErrorTwoTone';
import DesignServicesTwoToneIcon from '@material-ui/icons/DesignServicesTwoTone';
import SupportTwoToneIcon from '@material-ui/icons/SupportTwoTone';
import ReceiptTwoToneIcon from '@material-ui/icons/ReceiptTwoTone'; */
import AppsIcon from '@material-ui/icons/Apps';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import RemoveIcon from '@material-ui/icons/Remove';
import { Property } from 'src/types/property';

export interface MenuItem {
  id?: string;
  openPartialLink?: string;
  link?: string;
  state?: any;
  icon?: ReactNode;
  iconProps?: any;
  badge?: string;
  items?: MenuItem[];
  name: string;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}

export const buildMenuItemsFromProperty = (property?: Property): Array<MenuItems> => {
  if (property) {
    return [
      {
        heading: 'Brands',
        items: property?.brands.map((brand) => ({
          id: brand.id,
          name: brand.name,
          icon: AppsIcon,
          link: `/cardfactoryx3/`,
          openPartialLink: `/cardfactoryx3/${property.id}/${brand.id}`,
          items: brand.brandTypes.map((brandType) => ({
            id: brandType.id,
            name: brandType.name,
            icon: FiberManualRecordIcon,
            link: `/cardfactoryx3/${property.id}/${brand.id}/${brandType.id}`,
            openPartialLink: `/cardfactoryx3/${property.id}/${brand.id}/${brandType.id}`,
            iconProps: { style: { fontSize: '0.34rem', marginLeft: 12 } },
            items: [
              {
                name: 'Product Description',
                link: `/cardfactoryx3/${property.id}/${brand.id}/${brandType.id}/product`,
                icon: RemoveIcon,
                iconProps: { style: { fontSize: '0.34rem', marginLeft: 24 } }
              },
              {
                name: 'Program',
                link: `/cardfactoryx3/${property.id}/${brand.id}/${brandType.id}/waves`,
                icon: RemoveIcon,
                state: { brand, brandType },
                iconProps: { style: { fontSize: '0.34rem', marginLeft: 24 } }
              }
            ]
          }))
        }))
      }
    ];
  }
  return [];
};
