import { useContext, useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { SidebarContext } from 'src/contexts/SidebarContext';
import Logo from 'src/components/Logo';

import { Box, Drawer, Hidden } from '@material-ui/core';

import SidebarMenu from './SidebarMenu';
import { COLOR_BLACK, REX3_BLACK, REX3_WHITE } from 'src/theme/schemes/Rex3Theme';
import QueryContainer, { TypeOnChangeValue } from 'src/components/ui/QueryContainer';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import { useParams } from 'react-router';
import { ApolloError, useQuery } from '@apollo/client';
import GetPropertyQuery, { GetPropertyData, GetPropertyVars } from 'src/graphql/queries/GetPropertyQuery';
import { useSnackbar } from 'notistack';
import { Property } from 'src/types/property';

const useStyles = makeStyles((theme: Theme) => ({
  centerStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  sidebarWrapper: {
    width: 280,
    color: REX3_WHITE,
    background: REX3_BLACK,
    height: '100%',
    [theme.breakpoints.up('lg')]: {
      position: 'fixed',
      zIndex: 10,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0
    }
  },
  topSection: {
    display: 'flex',
    height: 88,
    alignItems: 'center',
    backgroundColor: COLOR_BLACK
  }
}));

function Sidebar() {
  const classes = useStyles();
  const [property, setProperty] = useState<Property>(null);
  const [loadSideBarData, setLoadSideBarData] = useState<boolean>(false);
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const { propertyId } = useParams();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const closeSidebar = () => toggleSidebar();

  const { loading: loadingProperty, data: propertyData } = useQuery<GetPropertyData, GetPropertyVars>(GetPropertyQuery, {
    fetchPolicy: 'no-cache',
    variables: {
      id: Number(propertyId)
    },
    skip: !propertyId || Number.isNaN(propertyId),
    onError: (error: ApolloError) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  });
  useEffect(() => {
    if (!loadingProperty && !loadSideBarData) {
      setProperty(propertyData?.getProperty);
      setLoadSideBarData(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingProperty]);

  const onChangeProperty = (value: TypeOnChangeValue) => {
    setProperty(value as any);
  };

  return (
    <>
      <Hidden lgUp={false}>
        <Box className={classes.sidebarWrapper}>
          <Scrollbars autoHide>
            <Box className={classes.topSection}>
              <Logo />
            </Box>
            <div className={classes.centerStyle}>
              <QueryContainer
                isForm={false}
                label="Property"
                technicalType="property"
                name="property"
                value={property}
                required={false}
                textIfEmptyData={t('Missing property')}
                fullWidth={false}
                onChange={onChangeProperty}
                maxWidth={250}
                minWidth={250}
                textFieldSize="small"
              />
            </div>
            <SidebarMenu property={property} />
          </Scrollbars>
        </Box>
      </Hidden>
      <Hidden lgUp>
        <Drawer anchor="left" open={sidebarToggle} onClose={closeSidebar} variant="temporary" elevation={9}>
          <Box className={classes.sidebarWrapper}>
            <Scrollbars autoHide>
              <Box className={classes.topSection}>
                <Logo />
              </Box>
              <SidebarMenu property={property} />
            </Scrollbars>
          </Box>
        </Drawer>
      </Hidden>
    </>
  );
}

export default Sidebar;
