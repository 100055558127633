import { TextField, TextFieldProps } from '@material-ui/core';
import { Control, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';
import { useEffect } from 'react';
import { REX3_GREY_2 } from 'src/theme/schemes/Rex3Theme';
import { uuidv4 } from 'src/utils/datatable';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FilterOptionsState } from '@material-ui/lab';
import { useInputStyle } from 'src/theme/useInputStyle';

type Props<T, K> = {
  name: keyof T;
  label: string;
  labelAccessor: string;
  defaultValue: K | null;
  options: Array<K>;
  required: boolean;
  control: Control<T>;
  error?: boolean;
  helperText?: string;
  fullWidth: boolean;
  minWidth?: number | null;
  maxWidth?: number | null;
  multiple?: boolean;
  filterOptions?: (options: any, state: FilterOptionsState<any>) => any[];
  textFieldSize?: TextFieldProps['size'];
};

export default function ReactHookFormAutocomplete<T, K>({
  name,
  label,
  labelAccessor = 'title',
  defaultValue = null,
  options,
  required,
  control,
  error,
  helperText,
  fullWidth,
  minWidth = 300,
  maxWidth = 350,
  multiple = false,
  filterOptions,
  textFieldSize = 'medium'
}: Props<T, K>) {
  const inputClasses = useInputStyle(fullWidth ? { minWidth: null, maxWidth: null } : { minWidth, maxWidth });
  const { t } = useTranslation();
  const {
    field: { name: nameController, onChange: onChangeWithController, onBlur: onBlurWithController, ref, value }
  } = useController<T>({
    name: name as any,
    control,
    defaultValue: defaultValue as any
  });

  // Buypass default value (watch not working on first render otherwise)
  useEffect(() => {
    if (defaultValue) {
      onChangeWithController(defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Autocomplete
      id={`autocomplete-${label}-${uuidv4()}`}
      classes={inputClasses}
      fullWidth={fullWidth}
      multiple={multiple}
      filterSelectedOptions
      noOptionsText={t('No option text')}
      loadingText={t('Loading')}
      options={options}
      value={value as string | K[] | NonNullable<K> | (string | K | K[])[]}
      getOptionLabel={(option) => get(option, [labelAccessor], '')}
      getOptionSelected={(option: K, value: K) => get(option, ['id']) === get(value, ['id'])}
      onChange={(e, value) => onChangeWithController(value)}
      onBlur={onBlurWithController}
      filterOptions={filterOptions}
      renderInput={(params) => (
        <TextField
          {...params}
          inputRef={ref}
          name={nameController}
          label={label}
          variant="outlined"
          error={error}
          helperText={helperText}
          required={required}
          InputLabelProps={{
            style: {
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              width: '100%',
              color: REX3_GREY_2
            }
          }}
          size={textFieldSize}
        />
      )}
    />
  );
}
