import React, { createContext, useState } from 'react';

// eslint-disable-next-line @typescript-eslint/ban-types
type initialTechnicalsProps = { technicals: any; setTechnicalsState: React.Dispatch<React.SetStateAction<any>> };

const initialTechnicals: initialTechnicalsProps = {
  technicals: {},
  setTechnicalsState: () => {
    /* DO SOMETHING */
  },
};

export const TechnicalContext = createContext(initialTechnicals);

type Props = {
  children: JSX.Element;
};

export default function TechnicalProvider({ children }: Props) {
  const [technicalsState, setTechnicalsState] = useState(initialTechnicals.technicals);

  return <TechnicalContext.Provider value={{ technicals: technicalsState, setTechnicalsState }}>{children}</TechnicalContext.Provider>;
}
