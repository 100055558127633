import { ChangeEvent, useState } from 'react';
import { Card, CardContent, CardHeader, Typography, Divider, Tabs, Tab, Box, makeStyles, Theme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ActivityChart from './ActivityChart';
import { REX3_RED, REX3_GREY_2 } from 'src/theme/schemes/Rex3Theme';
import clsx from 'clsx';
import useGlobalStyles from 'src/theme/useGlobalStyles';

const useStyles = makeStyles((theme: Theme) => ({
  container: { height: '100%' },
  activityChart: {
    height: 260
  },
  box: { padding: theme.spacing(3), height: 260 },
  tabRoot: {
    textTransform: 'none !important' as 'none',
    color: `${REX3_GREY_2} !important`,
    '&.Mui-selected': {
      color: `${REX3_RED} !important`
    }
  },
  indicator: {
    backgroundColor: `${REX3_RED} !important`
  },
  typo: {
    fontSize: '1rem'
  }
}));

function Activity() {
  const { t }: { t: any } = useTranslation();
  const classes = useStyles();
  const globalClasses = useGlobalStyles();

  const [currentTab, setCurrentTab] = useState<string>('weekly');

  const tabs = [
    { value: 'daily', label: t('Daily') },
    { value: 'weekly', label: t('Weekly') },
    { value: 'monthly', label: t('Monthly') }
  ];

  const handleTabsChange = (event: ChangeEvent<{}>, value: string): void => {
    setCurrentTab(value);
  };

  const calories = {
    day: {
      current: [344, 512, 829, 696, 847, 437, 935, 433, 962],
      previous: [740, 367, 372, 478, 459, 630, 894, 556, 369]
    },
    week: {
      current: [2662, 2583, 2746, 4756, 4201, 1869, 5694],
      previous: [4432, 5917, 5774, 4865, 3638, 4126, 5612]
    },
    month: {
      current: [34471, 37403, 10192, 48243, 37464, 32881, 43357, 40646, 36191, 25000, 10435, 4128],
      previous: [22021, 16157, 11778, 31648, 35923, 28934, 19554, 15607, 28901, 26400, 11091, 44578]
    }
  };

  const generic = {
    day: ['12:00 AM', '3:00 AM', '6:00 AM', '9:00 AM', '12:00 PM', '3:00 PM', '6:00 PM', '9:00 PM', '12:00 PM'],
    month: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    week: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
  };

  return (
    <Card className={classes.container}>
      <CardHeader
        title={
          <>
            <Typography variant="body1" component="span" className={clsx({ [globalClasses.bold]: true, [classes.typo]: true })}>
              Sales Activity
            </Typography>
            <Typography variant="body2" component="span">
              {` (Updated weekly)`}
            </Typography>
          </>
        }
      />
      <Divider />
      <CardContent>
        <Tabs
          onChange={handleTabsChange}
          value={currentTab}
          variant="scrollable"
          scrollButtons="auto"
          textColor="primary"
          indicatorColor="primary"
          classes={{ indicator: classes.indicator }}
        >
          {tabs.map((tab) => (
            <Tab key={tab.value} label={tab.label} value={tab.value} className={classes.tabRoot} />
          ))}
        </Tabs>
      </CardContent>

      <Box className={classes.box}>
        {currentTab === 'daily' && (
          <Box className={classes.activityChart}>
            <ActivityChart data={calories.day} labels={generic.day} className={classes.activityChart} />
          </Box>
        )}
        {currentTab === 'weekly' && (
          <Box className={classes.activityChart}>
            <ActivityChart data={calories.week} labels={generic.week} className={classes.activityChart} />
          </Box>
        )}
        {currentTab === 'monthly' && (
          <Box className={classes.activityChart}>
            <ActivityChart data={calories.month} labels={generic.month} className={classes.activityChart} />
          </Box>
        )}
      </Box>
    </Card>
  );
}

export default Activity;
