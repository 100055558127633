import { createTheme } from '@material-ui/core';

// import i18n from 'src/i18n/i18n';

export const COLOR_GREEN = 'green';
export const COLOR_RED = 'red';
export const COLOR_BLACK = 'black';

export const COLOR_LIGHT_GREEN = '#ABD22E';
export const COLOR_YELLOW = '#E4B010';
export const COLOR_ORANGE = '#FF9400';

export const REX3_BLACK = '#1e2229';
export const REX3_WHITE = '#FFFFFF';
export const REX3_GREY = '#eff0f8';
export const REX3_GREY_2 = '#898c9f';
export const REX3_GREY_3 = '#EEF0F8';
export const REX3_GREY_4 = '#E5EAEE';
export const REX3_GREY_5 = '#F4F6F9';
export const REX3_RED = '#fe504e';
export const REX3_RED_2 = '#C04141';
export const REX3_BLUE = '#000c57';

// @ts-ignore
export const Rex3Theme = createTheme({
  palette: {
    primary: {
      main: REX3_BLACK
    },
    secondary: {
      main: REX3_RED
    }
  },
  typography: {
    body1: {
      fontSize: '0.75rem'
    },
    fontFamily: [
      'Poppins',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(',')
  }
});
