import { useState } from 'react';
import { Button } from '@material-ui/core';
import useAuth from 'src/hooks/useAuth';
import useRefMounted from 'src/hooks/useRefMounted';
import { useTranslation } from 'react-i18next';
import { toAbsoluteUrl } from 'src/utils/utils';
import Alert from '@material-ui/lab/Alert';

import { makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  imgWrapper: {
    marginRight: theme.spacing(1),
    width: 32
  }
}));

function LoginAuth0() {
  const classes = useStyles();
  const { loginWithPopup } = useAuth() as any;
  const [error, setError] = useState<string | null>(null);
  const isMountedRef = useRefMounted();
  const { t }: { t: any } = useTranslation();

  const handleLogin = async (): Promise<void> => {
    try {
      await loginWithPopup();
    } catch (err) {
      console.error(err);
      if (isMountedRef.current) {
        setError(err.message);
      }
    }
  };

  return (
    <>
      {error && <Alert severity="error">{error}</Alert>}

      <Button
        fullWidth
        onClick={handleLogin}
        size="large"
        /* /* sx={{ py: 2 }} */
        variant="outlined"
      >
        <img className={classes.imgWrapper} alt="Auth0" src={toAbsoluteUrl('/static/images/logo/auth0.svg')} />
        {t('Sign in with')} Auth0
      </Button>
    </>
  );
}

export default LoginAuth0;
