import { FC } from 'react';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';
import { REX3_WHITE, REX3_RED, REX3_BLACK, REX3_GREY, REX3_GREY_2 } from 'src/theme/schemes/Rex3Theme';

interface ActivityChartProps {
  className?: string;
  data: any;
  labels: string[];
}

const ActivityChart: FC<ActivityChartProps> = ({ data: dataProp, labels, ...rest }) => {
  const data = {
    datasets: [
      {
        label: 'Previous Period',
        backgroundColor: REX3_GREY,
        data: dataProp.previous,
        borderColor: 'blue',
        pointBorderColor: REX3_WHITE,
        pointBorderWidth: 3,
        pointRadius: 6,
        pointHoverRadius: 10,
        pointHoverBackgroundColor: 'blue',
        pointHoverBorderColor: REX3_WHITE,
        pointHoverColor: 'blue',
        pointHoverBorderWidth: 4,
        pointBackgroundColor: 'blue',
        borderDash: [10, 5],
        type: 'line'
      },
      {
        label: 'Current Period',
        backgroundColor: 'transparent',
        data: dataProp.current,
        borderColor: REX3_RED,
        pointBorderColor: REX3_WHITE,
        pointBorderWidth: 3,
        pointRadius: 6,
        pointHoverRadius: 10,
        pointHoverBackgroundColor: REX3_RED,
        pointHoverBorderColor: REX3_WHITE,
        pointHoverColor: REX3_RED,
        pointHoverBorderWidth: 4,
        pointBackgroundColor: REX3_RED
      }
    ],
    labels
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    cornerRadius: 6,
    animation: false,
    legend: {
      display: false
    },
    layout: {
      padding: 0
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
            drawBorder: false
          },
          ticks: {
            padding: 18,
            fontColor: REX3_BLACK
          }
        }
      ],
      yAxes: [
        {
          gridLines: {
            borderDash: [6],
            borderDashOffset: [0],
            color: REX3_GREY_2,
            drawBorder: false,
            zeroLineBorderDash: [6],
            zeroLineBorderDashOffset: [0],
            zeroLineColor: REX3_GREY_2
          },
          ticks: {
            padding: 12,
            fontColor: REX3_BLACK,
            beginAtZero: true,
            min: 0,
            maxTicksLimit: 5
          }
        }
      ]
    },
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      caretSize: 6,
      displayColors: true,
      yPadding: 8,
      xPadding: 16,
      borderWidth: 4,
      bodySpacing: 10,
      titleFontSize: 16,
      borderColor: REX3_BLACK,
      backgroundColor: REX3_BLACK,
      titleFontColor: REX3_WHITE,
      bodyFontColor: REX3_WHITE,
      footerFontColor: REX3_WHITE
    },
    hover: {
      mode: 'nearest',
      intersect: true
    }
  };

  return (
    <div {...rest}>
      <Line data={data} options={options} />
    </div>
  );
};

ActivityChart.propTypes = {
  data: PropTypes.object.isRequired,
  labels: PropTypes.array.isRequired
};

export default ActivityChart;
