import React from 'react';

import useGlobalStyles from 'src/theme/useGlobalStyles';
import { Box, LinearProgress, Typography, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import { REX3_RED } from 'src/theme/schemes/Rex3Theme';

const useStyles = makeStyles((theme: Theme) => ({
  container: { minHeight: '100vh', paddingTop: theme.spacing(5), paddingBottom: theme.spacing(5) },
  title: { fontSize: '1.4rem' },
  subtitle: { fontSize: '1.063rem' },
  subtitleContainer: { marginTop: theme.spacing(2) },
  avatarStyle: ({ color }: { color: string }) => ({
    marginRight: theme.spacing(2),
    width: theme.spacing(6),
    height: theme.spacing(6),
    backgroundColor: color,
    borderRadius: 6
  }),
  pourcentage: { fontSize: '1.438rem', fontWeight: 'bold' },
  statTitle: { fontSize: '1.125rem', fontWeight: 600, marginTop: theme.spacing(3), marginBottom: theme.spacing(1) },
  linearProgress: { width: '100%', marginTop: theme.spacing(3), borderRadius: 6, height: 12 },
  barColorPrimary: ({ color }: { color: string }) => ({
    backgroundColor: REX3_RED
  })
}));

type Props = {
  title: string;
  pourcentage: number;
  color: string;
};

export default function StatCard({ title, pourcentage, color }: Props) {
  const classes = useStyles({ color });
  const globalClasses = useGlobalStyles();

  return (
    <Box className={globalClasses.spacingInPaper}>
      <Box className={classes.avatarStyle} />
      <Typography component="div" className={clsx({ [classes.statTitle]: true })}>
        {title}
      </Typography>
      <Box>
        <Typography component="span" className={clsx({ [classes.pourcentage]: true })}>
          {pourcentage}%
        </Typography>
      </Box>
      <LinearProgress
        classes={{ root: classes.linearProgress, barColorPrimary: classes.barColorPrimary }}
        variant="determinate"
        value={pourcentage}
      />
    </Box>
  );
}
