import { FC, ReactNode } from 'react';
import { Box, makeStyles } from '@material-ui/core';

import Sidebar from './Sidebar';
import Header from './Header';
import { REX3_GREY } from 'src/theme/schemes/Rex3Theme';
import { Theme } from '@material-ui/core/styles';
import { Outlet } from 'react-router';

interface SidebarLayoutProps {
  children?: ReactNode;
}

const useStyles = makeStyles((theme: Theme) => ({
  mainWrapper: {
    flex: '1 1 auto',
    display: 'flex',
    height: '100%',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 280
    }
  },
  mainContent: {
    marginTop: 88,
    flex: '1 1 auto',
    overflow: 'auto',
    backgroundColor: REX3_GREY
  }
}));

const SidebarLayout: FC<SidebarLayoutProps> = ({ children }) => {
  const classes = useStyles();

  return (
    <>
      <Sidebar />
      <Box className={classes.mainWrapper}>
        <Header />
        <Box className={classes.mainContent}>{children || <Outlet />}</Box>
      </Box>
    </>
  );
};

export default SidebarLayout;
