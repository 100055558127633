import { Box, Hidden } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Theme } from '@material-ui/core/styles';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import { makeStyles } from '@material-ui/styles';
import { REX3_RED, REX3_WHITE } from 'src/theme/schemes/Rex3Theme';

const useStyles = makeStyles((theme: Theme) => ({
  logoWrapper: {
    color: REX3_WHITE,
    padding: theme.spacing(0, 3, 0, 3),
    display: 'flex',
    flex: 1,
    textDecoration: 'none',
    fontWeight: 'bold'
  },
  logoText: {
    fontSize: '0.938rem',
    fontWeight: 'bold'
  },
  logoTextWrapper: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    color: REX3_WHITE
  },
  icon: {
    transform: 'rotate(180deg)',
    color: REX3_RED
  }
}));

function Logo() {
  const classes = useStyles();

  return (
    <Link to="/cardfactoryx3" className={classes.logoWrapper}>
      <Hidden smDown>
        <Box className={classes.logoTextWrapper}>
          <Box className={classes.logoText}>CARDFACTORY X3</Box>
          <DoubleArrowIcon className={classes.icon} fontSize="small" />
        </Box>
      </Hidden>
    </Link>
  );
}

export default Logo;
