import React, { ChangeEvent, useContext, useEffect } from 'react';
import get from 'lodash/get';
// import GetClassDefinition from '@/graphql/queries/GetClassDefinitionQuery';
import { useTranslation } from 'react-i18next';
import { Control } from 'react-hook-form';
import { ApolloError, DocumentNode, useQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';
import Spinner from '../spinner/Spinner';
import MyAutocomplete from './MyAutocomplete';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import usePrevious from 'src/hooks/usePrevious';
import { COLOR_RED, REX3_RED } from 'src/theme/schemes/Rex3Theme';
import GetPropertyListingQuery from 'src/graphql/queries/GetPropertyListingQuery';
import { TechnicalContext } from 'src/contexts/TechnicalContext';
import GetSubjectListingQuery, { Subject } from 'src/graphql/queries/GetSubjectListingQuery';
import { FilterOptionsState } from '@material-ui/lab';
import { Property } from 'src/types/property';
import { TextFieldProps } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  emptyData: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textAlign: 'center',
    color: COLOR_RED
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}));

export type TypeOnChangeValue = UnionType | UnionType[] | null | undefined;

type DefaultItem = { label: string; id: string };
export type PropertyListingItem = { cursor: string; node: Property };
export type SubjectListingItem = { cursor: string; node: Subject };
export type UnionType = DefaultItem | Property | Subject;

const technicalsConfig: Record<
  string,
  {
    query: DocumentNode;
    responseName: string;
    // eslint-disable-next-line @typescript-eslint/ban-types
    variables: object;
    valuesAccessor: string;
    defaultValueAcessor: string | null;
    getObject?: (item: UnionType) => UnionType;
    labelAccessor: 'name';
  }
> = {
  property: {
    query: GetPropertyListingQuery,
    responseName: 'getPropertyListing',
    variables: {},
    valuesAccessor: 'edges',
    defaultValueAcessor: null,
    getObject: (item: UnionType) => (item as unknown as PropertyListingItem).node,
    labelAccessor: 'name'
  },
  subject: {
    query: GetSubjectListingQuery,
    responseName: 'getSubjectListing',
    variables: {},
    valuesAccessor: 'edges',
    defaultValueAcessor: null,
    getObject: (item: UnionType) => (item as unknown as SubjectListingItem).node,
    labelAccessor: 'name'
  }
};

type Props<T> = {
  label: string;
  onChange?: (value: TypeOnChangeValue) => void;
  technicalType: string;
  required: boolean;
  error?: boolean;
  helperText?: string;
  isForm: boolean;
  control?: Control<T> | null;
  value?: TypeOnChangeValue;
  name: keyof T;
  useTranslate?: boolean;
  textIfEmptyData?: string;
  fullWidth: boolean;
  minWidth?: number | null;
  maxWidth?: number | null;
  multiple?: boolean;
  variables?: Record<string, any> | null;
  filterOptions?: (options: any, state: FilterOptionsState<any>) => any[];
  textFieldSize?: TextFieldProps['size'];
};

export default function QueryContainer<T>({
  label,
  onChange = () => {
    /* TO DO SOMETHING */
  },
  technicalType,
  required = false,
  error = false,
  helperText = '',
  isForm = false,
  value = null,
  name,
  useTranslate = false,
  textIfEmptyData,
  fullWidth,
  minWidth = 300,
  maxWidth = 350,
  multiple = false,
  control,
  variables = null,
  filterOptions,
  textFieldSize = 'medium'
}: Props<T>) {
  const technicalConfig = technicalsConfig[technicalType];
  const classes = useStyles();
  const { technicals, setTechnicalsState } = useContext(TechnicalContext);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { loading, data } = useQuery<Record<string, unknown> | undefined>(technicalConfig.query, {
    variables: variables || technicalConfig.variables,
    fetchPolicy: 'no-cache',
    onError: (error: ApolloError) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  });
  const previousLoading = usePrevious(loading);

  const selectValues: Array<UnionType> = get(data, [`${technicalConfig.responseName}`, `${technicalConfig.valuesAccessor}`], []);
  const defaultValueAcessor = get(data, [`${technicalConfig.responseName}`, `${technicalConfig.defaultValueAcessor}`], null);
  const labelAccessor = technicalConfig.labelAccessor;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  /* @ts-ignore */
  const options = selectValues.reduce(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    /* @ts-ignore */
    (acc, curr) => {
      const object = typeof technicalConfig.getObject === 'function' ? technicalConfig.getObject(curr) : null;
      if (useTranslate) {
        return [
          ...acc,
          /*           {
            id: technicalConfig.getValue(curr),
            label: t(technicalConfig.getLabel(curr)),
            ...(object && { object })
          } */
          object
        ];
      }
      return [...acc, object];
    },
    []
  ) as Array<UnionType>;

  const defineAutoSelectValue = () => {
    if (options.length === 1) {
      if (multiple) {
        return [options[0]];
      }
      return options[0];
    }
    return null;
  };

  const defaultValue = options.find((e) => e.id === defaultValueAcessor);
  const autoselectValue = defineAutoSelectValue();
  const initialValue = defaultValue || autoselectValue;

  const onChangeAutocomplete = (
    // eslint-disable-next-line @typescript-eslint/ban-types
    event: ChangeEvent<{}>,
    value: TypeOnChangeValue
  ) => {
    onChange(value);
  };

  useEffect(() => {
    if (previousLoading && !loading) {
      setTechnicalsState({ ...technicals, [technicalType]: selectValues });
      if (onChange && defaultValue) {
        if (!multiple || (multiple && Array.isArray(defaultValue))) {
          onChange(defaultValue);
        }
      }
      if (!defaultValue && options.length === 1 && onChange) {
        if (multiple) {
          onChange([options[0]]);
        } else {
          onChange(options[0]);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, previousLoading]);

  if (loading) {
    return (
      <div className={classes.center}>
        <Spinner fontSize={24} borderWidth={2} color={REX3_RED} />
      </div>
    );
  }

  if (textIfEmptyData && options.length === 0) {
    return <div className={classes.emptyData}>{textIfEmptyData}</div>;
  }

  if (options.length > 0) {
    return (
      <MyAutocomplete<T, TypeOnChangeValue>
        label={label}
        labelAccessor={labelAccessor}
        required={required}
        error={error}
        helperText={helperText}
        isForm={isForm}
        control={control}
        value={value}
        name={name}
        useTranslate={useTranslate}
        textIfEmptyData={textIfEmptyData}
        fullWidth={fullWidth}
        minWidth={minWidth}
        maxWidth={maxWidth}
        onChangeAutocomplete={onChangeAutocomplete as any}
        multiple={multiple}
        initialValue={initialValue}
        options={options}
        filterOptions={filterOptions}
        textFieldSize={textFieldSize}
      />
    );
  }

  return null;
}
