import { Box, List, ListItem, ListItemText } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { NavLink } from 'react-router-dom';
import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { REX3_BLACK } from 'src/theme/schemes/Rex3Theme';

const useStyles = makeStyles((theme: Theme) => ({
  textStyle: {
    color: REX3_BLACK
  }
}));

function HeaderMenu() {
  const classes = useStyles();
  const { t }: { t: any } = useTranslation();

  return (
    <>
      <Box>
        <List disablePadding component={Box} display="flex">
          <ListItem classes={{ root: 'MuiListItem-indicators' }} button component={NavLink} to="/">
            <ListItemText className={classes.textStyle} primaryTypographyProps={{ noWrap: true }} primary={t('Dashboard')} />
          </ListItem>
        </List>
      </Box>
    </>
  );
}

export default HeaderMenu;
