import { ListSubheader, List } from '@material-ui/core';
import { useLocation, matchPath } from 'react-router-dom';
import SidebarMenuItem from './item';
import { MenuItem, buildMenuItemsFromProperty } from './items';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { REX3_GREY_2 } from 'src/theme/schemes/Rex3Theme';
import { Property } from 'src/types/property';

const useStyles = makeStyles((theme: Theme) => ({
  menuWrapper: {
    marginBottom: theme.spacing(1),
    padding: 0,
    '& > .MuiListSubheader-root': {
      color: REX3_GREY_2,
      textTransform: 'uppercase',
      fontSize: '0.813rem',
      padding: theme.spacing(2, 2),
      lineHeight: 1.4
    }
  }
}));

type Props = { property: Property };

function SidebarMenu({ property }: Props) {
  const classes = useStyles();
  const location = useLocation();
  const { t }: { t: any } = useTranslation();

  const renderSidebarMenuItems = ({ items, path }: { items: MenuItem[]; path: string }): JSX.Element => (
    <List>{items.reduce((ev, item) => reduceChildRoutes({ ev, item, path }), [])}</List>
  );

  const reduceChildRoutes = ({ ev, path, item }: { ev: JSX.Element[]; path: string; item: MenuItem }): Array<JSX.Element> => {
    const key = item.name;

    const exactMatch = item.link
      ? !!matchPath(
          {
            path: item.link,
            end: false
          },
          path
        )
      : false;

    if (item.items) {
      const partialMatch = item.openPartialLink
        ? !!matchPath(
            {
              path: item.openPartialLink,
              end: false
            },
            path
          )
        : false;

      ev.push(
        <SidebarMenuItem
          key={key}
          active={partialMatch}
          open={partialMatch}
          name={item.name}
          icon={item.icon}
          iconProps={item.iconProps}
          link={item.link}
          badge={item.badge}
          state={item.state}
        >
          {renderSidebarMenuItems({
            path,
            items: item.items
          })}
        </SidebarMenuItem>
      );
    } else {
      ev.push(
        <SidebarMenuItem
          key={key}
          active={exactMatch}
          name={item.name}
          link={item.link}
          badge={item.badge}
          icon={item.icon}
          iconProps={item.iconProps}
          state={item.state}
        />
      );
    }

    return ev;
  };

  const menuItems = buildMenuItemsFromProperty(property);
  return (
    <>
      {menuItems.map((section) => (
        <List className={classes.menuWrapper} key={section.heading} subheader={<ListSubheader disableSticky>{t(section.heading)}</ListSubheader>}>
          {renderSidebarMenuItems({
            items: section.items,
            path: location.pathname
          })}
        </List>
      ))}
    </>
  );
}

export default SidebarMenu;
