import routes from './router';
import { SnackbarProvider } from 'notistack';
import useScrollTop from 'src/hooks/useScrollTop';
import useAuth from 'src/hooks/useAuth';

import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { ApolloClient, InMemoryCache, ApolloProvider, ApolloLink, createHttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import TechnicalProvider from './contexts/TechnicalContext';
import TableProvider from './contexts/TableContext';
import AdapterDateFns from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Rex3Theme } from './theme/schemes/Rex3Theme';
import { useRoutes } from 'react-router';

const { REACT_APP_API_URL } = process.env;

const httpLink = createHttpLink({
  uri: REACT_APP_API_URL
});

const middlewareLink = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  });
  return forward(operation);
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) =>
      // eslint-disable-next-line no-console
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    );
  }
  if (networkError) {
    // eslint-disable-next-line no-console
    console.log(`[Network error]: ${networkError}`);
  }
});

const client = new ApolloClient({
  link: ApolloLink.from([errorLink, middlewareLink, httpLink]),
  credentials: 'include',
  // @ts-ignore
  fetchOptions: {
    mode: 'no-cors'
  },
  cache: new InMemoryCache()
});

const App = () => {
  // const content = useRoutes(routes);
  const content = useRoutes(routes);
  const auth = useAuth();
  useScrollTop();

  return (
    <ApolloProvider client={client}>
      <TechnicalProvider>
        <SnackbarProvider maxSnack={3} autoHideDuration={5000} anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
          <TableProvider>
            <ThemeProvider theme={Rex3Theme}>
              <MuiPickersUtilsProvider utils={AdapterDateFns}>
                <>
                  <CssBaseline />
                  {auth.isInitialized ? content : <>auth not initialized</>}
                </>
              </MuiPickersUtilsProvider>
            </ThemeProvider>
          </TableProvider>
        </SnackbarProvider>
      </TechnicalProvider>
    </ApolloProvider>
  );
};
export default App;
