import { makeStyles, Theme } from '@material-ui/core';
import { REX3_GREY_2 } from './schemes/Rex3Theme';

export const useInputStyle = makeStyles((theme: Theme) => ({
  root: ({ minWidth, maxWidth }: { minWidth: number | null; maxWidth: number | null }) => ({
    ...(minWidth && { minWidth }),
    ...(maxWidth && { maxWidth })
  }),
  endAdornment: {
    color: REX3_GREY_2,
    '& button': {
      color: `${REX3_GREY_2} !important`
    }
  },
  groupLabel: {
    color: `${REX3_GREY_2} !important`
  },
  inputRoot: {
    color: `${REX3_GREY_2} !important`,
    // padding: `${theme.spacing(0.5)}px !important`,
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: `${REX3_GREY_2} !important`
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: `${REX3_GREY_2} !important`
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: `${REX3_GREY_2} !important`
    }
  }
}));
