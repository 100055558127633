import { useRef, useState } from 'react';
import useAuth from 'src/hooks/useAuth';
import { NavLink, useNavigate } from 'react-router-dom';

import { Avatar, Box, Button, Divider, Hidden, List, ListItem, ListItemText, Popover, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import InboxTwoToneIcon from '@material-ui/icons/InboxTwoTone';
import { Theme } from '@material-ui/core/styles';
import ExpandMoreTwoToneIcon from '@material-ui/icons/ExpandMoreTwoTone';
import AccountBoxTwoToneIcon from '@material-ui/icons/AccountBoxTwoTone';
import LockOpenTwoToneIcon from '@material-ui/icons/LockOpenTwoTone';
import AccountTreeTwoToneIcon from '@material-ui/icons/AccountTreeTwoTone';
import { makeStyles } from '@material-ui/styles';
import { REX3_BLUE, REX3_GREY_2, REX3_WHITE } from 'src/theme/schemes/Rex3Theme';

const useStyles = makeStyles((theme: Theme) => ({
  userJobTitleStyle: { color: REX3_GREY_2 },
  userNameStyle: {
    fontWeight: theme.typography.fontWeightBold,
    color: REX3_BLUE,
    display: 'block'
  },
  menuUserBox: {
    minWidth: 210,
    background: REX3_WHITE,
    padding: theme.spacing(2)
  },
  userBoxText: { textAlign: 'left', paddingLeft: theme.spacing(1) },
  userBoxButton: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  list: { padding: theme.spacing(1) },
  listItemText: { marginLeft: theme.spacing(1) }
}));

function HeaderUserbox() {
  const classes = useStyles();
  const { t }: { t: any } = useTranslation();

  const navigate = useNavigate();

  const { user, logout } = useAuth();

  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose();
      await logout();
      navigate('/');
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <Button className={classes.userBoxButton} color="secondary" ref={ref} onClick={handleOpen}>
        <Avatar variant="rounded" alt={user.name} src={user.avatar} />
        <Hidden mdDown>
          <Box className={classes.userBoxText}>
            <Typography variant="inherit" className={classes.userNameStyle}>
              {user.name}
            </Typography>
            <Typography variant="body2" className={classes.userJobTitleStyle}>
              {user.jobtitle}
            </Typography>
          </Box>
        </Hidden>
        <Hidden smDown>
          <ExpandMoreTwoToneIcon fontSize="small" /* sx={{ ml: 1 }} */ />
        </Hidden>
      </Button>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Box className={classes.menuUserBox} display="flex">
          <Avatar variant="rounded" alt={user.name} src={user.avatar} />
          <Box className={classes.userBoxText}>
            <Typography variant="inherit" className={classes.userNameStyle}>
              {user.name}
            </Typography>
            <Typography variant="body2" className={classes.userJobTitleStyle}>
              {user.jobtitle}
            </Typography>
          </Box>
        </Box>
        <Divider /* sx={{ mb: 0 }} */ />
        <List className={classes.list} component="nav">
          <ListItem button to="/management/user/1" component={NavLink}>
            <AccountBoxTwoToneIcon fontSize="small" />
            <ListItemText className={classes.listItemText} primary={t('Profile')} />
          </ListItem>
          <ListItem button to="/applications/mailbox/inbox" component={NavLink}>
            <InboxTwoToneIcon fontSize="small" />
            <ListItemText className={classes.listItemText} primary={t('Inbox')} />
          </ListItem>
          <ListItem button to="/applications/projects-board" component={NavLink}>
            <AccountTreeTwoToneIcon fontSize="small" />
            <ListItemText className={classes.listItemText} primary={t('Projects')} />
          </ListItem>
        </List>
        <Divider />
        <Box sx={{ m: 1 }}>
          <Button color="primary" fullWidth onClick={handleLogout}>
            <LockOpenTwoToneIcon fontSize="small" /* sx={{ mr: 1 }} */ />
            {t('Sign out')}
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default HeaderUserbox;
