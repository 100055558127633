import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { REX3_GREY_3, REX3_BLACK, REX3_RED, REX3_WHITE } from './schemes/Rex3Theme';

export default makeStyles((theme: Theme) => ({
  lineEditionStyle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  firstLineContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center'
    }
  },
  button: { marginLeft: theme.spacing(2), padding: theme.spacing(1, 3, 1, 3) },
  buttonContained1: {
    fontWeight: `500 !important` as unknown as 500,
    backgroundColor: `${REX3_GREY_3} !important`,
    textTransform: 'none !important' as 'none',
    color: `${REX3_BLACK} !important`
  },
  buttonContained2: {
    fontWeight: `500 !important` as unknown as 500,
    backgroundColor: `${REX3_RED} !important`,
    textTransform: 'none !important' as 'none',
    color: REX3_WHITE,
    '&.Mui-disabled': {
      color: REX3_WHITE,
      opacity: 0.5
    }
  },
  buttonContained3: {
    fontWeight: `500 !important` as unknown as 500,
    backgroundColor: `${REX3_BLACK} !important`,
    textTransform: 'none !important' as 'none',
    color: REX3_WHITE,
    '&.Mui-disabled': {
      color: REX3_WHITE,
      opacity: 0.5
    }
  },
  title: { fontSize: '1rem !important', marginLeft: theme.spacing(2) },
  titleSubSection: { fontSize: '1rem !important' },
  bold: { fontWeight: 'bold !important' as 'bold' },
  spaceBetweenBlock: { marginTop: theme.spacing(5) },
  divider: {
    backgroundColor: `#e0e0e0 !important`
  },
  valueInfo: { textAlign: 'right' },
  spacingInPaper: { padding: `${theme.spacing(3, 3, 3, 3)} !important` },
  spacingInBox: { padding: `${theme.spacing(3, 3, 2, 3)} !important` },
  updateIcon: { color: REX3_RED },
  takeAllHeight: { height: '100%' },
  containerSpinnerParent: {
    display: 'flex',
    width: '100%',
    flex: 1,
    minHeight: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(1),
    textAlign: 'center',
    flexDirection: 'column'
  },
  breadcrumbsContainer: { backgroundColor: REX3_WHITE, padding: theme.spacing(1.5, 8, 1.5, 8) },
  titleSectionContainer: { fontSize: '1rem !important', display: 'flex', justifyContent: 'space-between', alignItems: 'center', minHeight: 50 },
  containerButtons: { marginBottom: theme.spacing(2) },
  blockTitle: { marginBottom: theme.spacing(2) },
  displayNone: {
    display: 'none !important'
  },
  waveEditionButtons: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(2),
      minWidth: 250
    }
  },
  designButtons: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
      minWidth: 250
    }
  }
}));
