import Footer from 'src/components/Footer';
import { Helmet } from 'react-helmet-async';

import { Button, Container, Grid, Paper, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useParams } from 'react-router';
import GetBrandTypeQuery, { GetBrandTypeData, GetBrandTypeVars } from 'src/graphql/queries/GetBrandTypeQuery';
import { ApolloError, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { GRID_SPACING } from 'src/constants/design';
import SuspenseLoader from 'src/components/SuspenseLoader';
import useGlobalStyles from 'src/theme/useGlobalStyles';
import clsx from 'clsx';
import StatCard from 'src/components/functionnal/brandType/StatCard';
import Activity from 'src/components/functionnal/brandType/Activity';
import { REX3_GREY_2 } from 'src/theme/schemes/Rex3Theme';
import { toAbsoluteUrl } from 'src/utils/utils';

const useStyles = makeStyles((theme: Theme) => ({
  container: { minHeight: '100vh', paddingTop: theme.spacing(5), paddingBottom: theme.spacing(5) },
  title: { fontSize: '1.4rem' },
  subtitle: { fontSize: '1.063rem' },
  subtitleContainer: { marginTop: theme.spacing(2) },
  titleReport: { fontSize: '1.563rem', fontWeight: 'bold' },
  subtitleReport: {
    fontSize: '0.938rem',
    color: REX3_GREY_2
  },
  button: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(1, 3, 1, 3)
  },
  imgWrapper: {
    width: 150,
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(2)
    }
  },
  containerImage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

function BrandType() {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const { brandTypeId } = useParams();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { loading: loadingBrandType, data: dataBrandType } = useQuery<GetBrandTypeData, GetBrandTypeVars>(GetBrandTypeQuery, {
    fetchPolicy: 'no-cache',
    variables: {
      id: Number(brandTypeId)
    },
    skip: Number.isNaN(brandTypeId),
    onError: (error: ApolloError) => {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  });

  const brandType = dataBrandType?.getBrandType;

  return (
    <>
      {loadingBrandType ? (
        <SuspenseLoader />
      ) : (
        <>
          <Helmet>
            <title>{brandType?.name}</title>
          </Helmet>
          <Container maxWidth="lg" className={classes.container}>
            <Grid container direction="row" spacing={GRID_SPACING}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography component="div" className={clsx({ [globalClasses.bold]: true, [classes.title]: true })}>
                  {brandType?.name}
                </Typography>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className={classes.subtitleContainer}>
                  <Typography component="div" className={clsx({ [classes.subtitle]: true })}>
                    Collect mashups of your favorite MLB players and classic designs, now with shortprints and parallels to chase! Next Wave - 07
                    January 2021
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Grid container spacing={GRID_SPACING}>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Paper>
                      <StatCard title="Stat 1" pourcentage={93} color="#ECFBE6" />
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Paper>
                      <StatCard title="Stat 1" pourcentage={93} color="#EAF9FF" />
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Paper>
                      <StatCard title="Stat 1" pourcentage={93} color="#FFF6E7" />
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Paper>
                      <StatCard title="Stat 1" pourcentage={93} color="#FFE8EC" />
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Activity />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <Paper>
                  <Grid container className={clsx({ [globalClasses.spacingInPaper]: true })}>
                    <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                      <Typography component="div" className={clsx({ [classes.titleReport]: true })}>
                        Want full report?
                      </Typography>

                      <Typography component="div" className={clsx({ [classes.subtitleReport]: true })}>
                        Get it today by clicking the button below.
                      </Typography>

                      <Button variant="contained" className={clsx(classes.button, globalClasses.buttonContained2)}>
                        Download now
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={12} md={5} lg={5} xl={5} className={classes.containerImage}>
                      <img className={classes.imgWrapper} alt="Auth0" src={toAbsoluteUrl('/static/images/overview/Croods Chart.svg')} />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Container>
          <Footer />
        </>
      )}
    </>
  );
}

export default BrandType;
