import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
/* import { PartialRouteObject } from 'react-router'; */

import Guest from 'src/components/Guest';
import Authenticated from 'src/components/Authenticated';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';
import SuspenseLoader from 'src/components/SuspenseLoader';
import BrandType from './content/dashboards/CardFactoryX3/BrandType';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Dashboards

const Dashboard = Loader(lazy(() => import('src/content/dashboards/CardFactoryX3/Dashboard')));
const Waves = Loader(lazy(() => import('src/content/dashboards/CardFactoryX3/Waves')));
const WavePage = Loader(lazy(() => import('src/content/dashboards/CardFactoryX3/WavePage')));
const Product = Loader(lazy(() => import('src/content/dashboards/CardFactoryX3/Product')));

// Auth

const LoginCover = Loader(lazy(() => import('src/content/pages/Auth/Login/Cover')));
const LoginBasic = Loader(lazy(() => import('src/content/pages/Auth/Login/Basic')));

const RegisterCover = Loader(lazy(() => import('src/content/pages/Auth/Register/Cover')));
const RegisterBasic = Loader(lazy(() => import('src/content/pages/Auth/Register/Basic')));
const RegisterWizard = Loader(lazy(() => import('src/content/pages/Auth/Register/Wizard')));

const RecoverPassword = Loader(lazy(() => import('src/content/pages/Auth/RecoverPassword')));

// Status

const Status404 = Loader(lazy(() => import('src/content/pages/Status/Status404')));
const Status500 = Loader(lazy(() => import('src/content/pages/Status/Status500')));
const StatusComingSoon = Loader(lazy(() => import('src/content/pages/Status/ComingSoon')));
const StatusMaintenance = Loader(lazy(() => import('src/content/pages/Status/Maintenance')));

const routes = [
  {
    path: 'account',
    children: [
      {
        path: 'login',
        element: (
          <Guest>
            <LoginCover />
          </Guest>
        )
      },
      {
        path: 'register',
        element: (
          <Guest>
            <RegisterCover />
          </Guest>
        )
      },
      {
        path: 'login-basic',
        element: <LoginBasic />
      },
      {
        path: 'login-cover',
        element: <LoginCover />
      },
      {
        path: 'recover-password',
        element: <RecoverPassword />
      },
      {
        path: 'register',
        element: (
          <Guest>
            <RegisterCover />
          </Guest>
        )
      },
      {
        path: 'register-basic',
        element: <RegisterBasic />
      },
      {
        path: 'register-cover',
        element: <RegisterCover />
      },
      {
        path: 'register-wizard',
        element: <RegisterWizard />
      }
    ]
  },
  {
    path: '*',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Navigate to="/account/login" replace />
      },
      {
        path: 'status',
        children: [
          {
            path: '/',
            element: <Navigate to="404" replace />
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />
          }
        ]
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  },
  {
    path: 'cardfactoryx3',
    children: [
      {
        path: '/',
        element: (
          <Authenticated>
            <SidebarLayout>
              <Dashboard />
            </SidebarLayout>
          </Authenticated>
        )
      },
      {
        path: ':propertyId/:brandId/:brandTypeId',
        element: (
          <Authenticated>
            <SidebarLayout>
              <BrandType />
            </SidebarLayout>
          </Authenticated>
        ),
        children: []
      },
      {
        path: ':propertyId/:brandId/:brandTypeId/waves',
        element: (
          <Authenticated>
            <SidebarLayout>
              <Waves />
            </SidebarLayout>
          </Authenticated>
        )
      },
      {
        path: ':propertyId/:brandId/:brandTypeId/waves/:waveId',
        element: (
          <Authenticated>
            <SidebarLayout>
              <WavePage />
            </SidebarLayout>
          </Authenticated>
        )
      },
      {
        path: ':propertyId/:brandId/:brandTypeId/product',
        element: (
          <Authenticated>
            <SidebarLayout>
              <Product />
            </SidebarLayout>
          </Authenticated>
        )
      }
    ]
  }
];

export default routes;
