import { BACK_TEXT, CARDTYPE_BASECARD, CARDTYPE_PARALLEL, CARDTYPE_SHORTPRINT } from 'src/constants/functionnal';
import { Subject, Team } from 'src/graphql/queries/GetSubjectListingQuery';
import { AddBaseSubjectCreatorType } from 'src/types/type';
import get from 'lodash/get';
import { Wave } from 'src/types/wave';
import { BrandType } from 'src/types/brandType';
import { CardType } from 'src/types/cardtype';
import { Product } from 'src/types/product';
import { MyFile } from 'src/components/dropzone/MyDropzone';

export const toAbsoluteUrl = (pathname: string) => {
  return process.env.PUBLIC_URL + pathname;
};

// TO REPLACE
export const createEmptyBaseSubjectsCreator = (wave: Wave, componentsData: Array<ComponentDTO>): Array<AddBaseSubjectCreatorType> => {
  let array: Array<AddBaseSubjectCreatorType> = [];
  for (let i = wave?.cardStart; i <= wave?.cardEnd; i++) {
    array = [
      ...array,
      {
        cardNumber: i,
        baseSubject: {
          subject: null,
          team: null
        },
        shortprint: false
      }
    ];
  }

  if (componentsData) {
    const baseComponent = componentsData?.find((e) => e.cardType?.cardType === CARDTYPE_BASECARD);
    const shortPrintComponent = componentsData?.find((e) => e.cardType?.cardType === CARDTYPE_SHORTPRINT);
    const cardWithShortPrint = shortPrintComponent?.cards?.find((e) => e.cardType.cardType === CARDTYPE_SHORTPRINT);

    const arrayFromServer = baseComponent?.cards.map(
      (card) =>
        ({
          baseSubject: {
            subject: get(card, ['baseSubject', 'subject'], null) as Subject | null,
            team: get(card, ['baseSubject', 'team'], null) as Team | null
          },
          cardNumber: card?.cardNumber,
          shortprint: cardWithShortPrint && cardWithShortPrint?.baseSubject?.subject?.id === card?.baseSubject?.subject?.id
        } as any as AddBaseSubjectCreatorType)
    );

    return array?.reduce((accumulator, element) => {
      const currentCard = arrayFromServer?.find((e) => e.cardNumber === element.cardNumber);
      if (currentCard) {
        return [...accumulator, currentCard];
      }
      return [...accumulator, element];
    }, []);
  } else {
    return array;
  }
};

export const createComponentAndCard = (
  wave: Wave,
  brandType: BrandType,
  baseSubjectsCreator: AddBaseSubjectCreatorType[],
  componentsData: Array<ComponentDTO>
): Array<ComponentDTO> => {
  const program = brandType?.program;
  const product = program?.product;
  const cardTypes = program?.cardTypes;
  //  const cardTypesWithoutShortprint = cardTypes?.filter((e) => e.cardType !== CARDTYPE_SHORTPRINT);
  const shortPrintCardType = cardTypes?.find((e) => e.cardType === CARDTYPE_SHORTPRINT);

  const components: Array<ComponentDTO> = cardTypes?.reduce((accumulator, cardType) => {
    if (cardType?.cardType === CARDTYPE_SHORTPRINT) {
      const baseSubjectCreator = baseSubjectsCreator.find((e) => e.shortprint === true);
      if (!baseSubjectCreator) {
        return accumulator;
      }
      const { cardNumber, baseSubject } = baseSubjectCreator;
      const cardShortprint = {
        id: null,
        cardType: shortPrintCardType,
        baseSubject,
        filename: generateFilename(product, baseSubjectCreator, shortPrintCardType, wave),
        backText: BACK_TEXT,
        cardNumber: cardNumber
      };
      const cards = [cardShortprint];

      return [...accumulator, { id: null, waveId: wave.id, cards: cards, cardType: cardType }];
      /*       if (baseSubjectCreator?.shortprint && ) {
        
      } */
    }
    const cards = baseSubjectsCreator.reduce((acc, baseSubjectCreator) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { shortprint, cardNumber, baseSubject } = baseSubjectCreator;
      const card = {
        id: null,
        cardType,
        baseSubject,
        filename: generateFilename(product, baseSubjectCreator, cardType, wave),
        backText: BACK_TEXT,
        cardNumber: cardNumber
      };

      return [...acc, card];
    }, []);

    return [...accumulator, { id: null, waveId: wave.id, cards: cards, cardType: cardType }];
  }, []);

  if (componentsData) {
    const mergedComponents: Array<ComponentDTO> = components.reduce((accumulator, component) => {
      let componentFromServer;

      component?.cards?.reduce((acc, card) => {
        if (!componentFromServer) {
          componentFromServer = componentsData.find((serverComponent) => {
            return serverComponent?.cards?.find((serverCard) => {
              return (
                serverCard?.cardNumber === card?.cardNumber &&
                serverCard?.baseSubject?.subject?.id === card?.baseSubject?.subject?.id &&
                serverCard?.cardType?.id === card?.cardType?.id
              );
            });
          });
        }
        return null;
      }, []);

      const newCards = component?.cards?.reduce((acc, card) => {
        if (componentFromServer) {
          const newCardFromServer = (componentFromServer as ComponentDTO | null)?.cards?.find(
            (cardFromServer) =>
              cardFromServer?.baseSubject?.subject?.id === card?.baseSubject?.subject?.id &&
              cardFromServer?.cardNumber === card?.cardNumber &&
              cardFromServer?.cardType?.id === card?.cardType?.id
          );
          return [...acc, { ...card, ...newCardFromServer }];
        }
        return [...acc, card];
      }, []);

      return [...accumulator, { ...component, ...componentFromServer, ...(componentFromServer && { id: componentFromServer?.id, cards: newCards }) }];
    }, []);

    return changeComponentsOrder(mergedComponents);
  }

  return changeComponentsOrder(components);
};

export const createMasterImages = (baseSubjectsCreator: Array<AddBaseSubjectCreatorType>, wave: Wave) => {
  const masterImagesFromWave = wave?.masterImages;

  const masterImages = baseSubjectsCreator.reduce((acc, curr, index) => {
    const currentMasterImageFromWave = masterImagesFromWave?.find((e) => e.order === index + 1);
    return [...acc, { id: get(currentMasterImageFromWave, ['id'], null), baseSubject: curr?.baseSubject, shortprint: false, order: index + 1 }];
  }, []);

  const shortprint = baseSubjectsCreator.find((e) => e.shortprint);

  if (shortprint) {
    const currentMasterImageFromWave = masterImagesFromWave?.find((e) => e.order === masterImages?.length + 1);
    return [
      ...masterImages,
      {
        id: get(currentMasterImageFromWave, ['id'], null),
        baseSubject: shortprint?.baseSubject,
        shortprint: shortprint?.shortprint,
        order: masterImages?.length + 1
      }
    ];
  }
  return [...masterImages];
};

export type ComponentDTO = {
  id: string;
  waveId: string;
  cardType: CardType;
  name: string;
  group: string;
  componentID: string;
  description: string;
  cards: Array<{
    id: string | null;
    cardNumber: number;
    backText: string;
    baseSubject: {
      cardNumber: number;
      subject: Subject | null;
      team: Team | null;
    };
    cardType: CardType;
    filename: string;
  }>;
};

function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

export const generateFilename = (product: Product, baseSubjectCreator: AddBaseSubjectCreatorType, cardType: CardType, wave: Wave) =>
  `${product?.sku} - ${generate2digitOrdernumber(wave?.orderNumber)} - ${wave?.orderNumber > 9 ? '00' : '000'}${baseSubjectCreator?.['cardNumber']}${
    cardType?.identifier ? ` - ${cardType?.identifier}` : ''
  }`;

export const generate2digitOrdernumber = (orderNumber: number) => `${orderNumber > 9 ? '' : '0'}${orderNumber}`;

export const generateFileFromBase64 = (base64: string, filename: string, type: string): MyFile => {
  const file = dataURLtoFile(`data:${type};base64,${base64}`, filename);
  const reader = new FileReader();
  reader.readAsArrayBuffer(file);
  return Object.assign(file, {
    preview: URL.createObjectURL(file)
  });
};

export const base64toBlob = (data: string, type: string) => {
  const base64WithoutPrefix = data.split(',')[1];
  const bytes = atob(base64WithoutPrefix);
  let length = bytes.length;
  let out = new Uint8Array(length);

  while (length--) {
    out[length] = bytes.charCodeAt(length);
  }
  return new Blob([out], { type });
};

export const handleCardType = (cardType: CardType) => {
  if (cardType.cardType === 'baseCard') {
    return 'Base Card';
  }
  if (cardType.cardType === 'shortprint') {
    return 'Shortprint';
  }
  if (cardType.cardType === 'parallel' && cardType.identifier === 'P10') {
    return 'Blue Parallel - P10';
  }
  if (cardType.cardType === 'parallel' && cardType.identifier === 'P5') {
    return 'Red Parallel - P5';
  }
  if (cardType.cardType === 'parallel' && cardType.identifier === 'P1') {
    return 'Gold Parallel - P1';
  }

  return null;
};

export const getDayFromIndex = (index: number): string => {
  return ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][index];
};

export const changeComponentsOrder = (components: Array<ComponentDTO>) => {
  let orderedComponents = [];
  const baseCardComponent = components.find((e) => e.cardType?.cardType === CARDTYPE_BASECARD);
  const shortPrintComponent = components.find((e) => e.cardType?.cardType === CARDTYPE_SHORTPRINT);
  // @ts-ignore
  const othersComponent = components.filter((e) => e.cardType?.cardType !== CARDTYPE_BASECARD && e.cardType?.cardType !== CARDTYPE_SHORTPRINT);

  if (baseCardComponent) {
    orderedComponents = [...orderedComponents, baseCardComponent];
  }
  if (shortPrintComponent) {
    orderedComponents = [...orderedComponents, shortPrintComponent];
  }
  if (othersComponent) {
    orderedComponents = [...orderedComponents, ...othersComponent];
  }

  return orderedComponents;
};

export const getFullProductName = (wave: Wave, set: string, product: Product) =>
  `${wave?.sku} Set # ${set} - ${product?.design?.name} - Print Run: ${wave.printRun}`;

export const getComponentName = (cardType: CardType) => {
  if (cardType?.cardType === CARDTYPE_BASECARD) {
    return 'Base';
  }
  if (cardType?.cardType === CARDTYPE_SHORTPRINT) {
    return 'Base Short Print';
  }
  if (cardType?.cardType === CARDTYPE_PARALLEL) {
    return `Base Parrallel /${cardType.identifier}`;
  }

  return null;
};

export const getComponentGroup = (cardType: CardType) => {
  if (cardType?.cardType === CARDTYPE_BASECARD) {
    return 'Base';
  }
  if (cardType?.cardType === CARDTYPE_SHORTPRINT) {
    return 'Base';
  }
  if (cardType?.cardType === CARDTYPE_PARALLEL) {
    return `Base Parrallel`;
  }

  return null;
};

export const pickTextColorBasedOnBgColorAdvanced = (bgColor, lightColor, darkColor) => {
  var color = bgColor.charAt(0) === '#' ? bgColor.substring(1, 7) : bgColor;
  var r = parseInt(color.substring(0, 2), 16); // hexToR
  var g = parseInt(color.substring(2, 4), 16); // hexToG
  var b = parseInt(color.substring(4, 6), 16); // hexToB
  var uicolors = [r / 255, g / 255, b / 255];
  var c = uicolors.map((col) => {
    if (col <= 0.03928) {
      return col / 12.92;
    }
    return Math.pow((col + 0.055) / 1.055, 2.4);
  });
  var L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];
  return L > 0.179 ? darkColor : lightColor;
};

export const beautifyNumber = (number: number) => `${number},00`;
