import { useContext } from 'react';

import { Box, Hidden, IconButton, Tooltip } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import MenuTwoToneIcon from '@material-ui/icons/MenuTwoTone';
import { SidebarContext } from 'src/contexts/SidebarContext';
import CloseTwoToneIcon from '@material-ui/icons/CloseTwoTone';

import HeaderMenu from './Menu';
import HeaderButtons from './Buttons';
import HeaderUserbox from './Userbox';
import { REX3_GREY, REX3_WHITE } from 'src/theme/schemes/Rex3Theme';
import { makeStyles } from '@material-ui/styles';
import { toAbsoluteUrl } from 'src/utils/utils';

const useStyles = makeStyles((theme: Theme) => ({
  toppsLogoStyle: { height: 40, marginRight: 50 },
  headerWrapper: {
    height: 88,
    color: REX3_WHITE,
    padding: theme.spacing(0, 2),
    right: 0,
    zIndex: 1002,
    backgroundColor: REX3_WHITE,
    position: 'fixed',
    justifyContent: 'space-between',
    width: '100%',
    borderBottom: `1px solid ${REX3_GREY}`,
    [theme.breakpoints.up('lg')]: {
      left: 280,
      width: 'auto'
    }
  }
}));

function Header() {
  const classes = useStyles();
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);

  return (
    <Box className={classes.headerWrapper} display="flex" alignItems="center">
      <Box display="flex" alignItems="center">
        <Hidden mdDown>
          <img className={classes.toppsLogoStyle} alt="BTC" src={toAbsoluteUrl('/static/images/logo/toppsLogo.png')} />
          <HeaderMenu />
        </Hidden>
      </Box>
      <Box display="flex" alignItems="center">
        <HeaderButtons />
        <HeaderUserbox />
        <Hidden lgUp>
          <Tooltip arrow title="Search">
            <IconButton color="primary" onClick={toggleSidebar} size="medium">
              {!sidebarToggle ? <MenuTwoToneIcon fontSize="small" /> : <CloseTwoToneIcon fontSize="small" />}
            </IconButton>
          </Tooltip>
        </Hidden>
      </Box>
    </Box>
  );
}

export default Header;
